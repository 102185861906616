import * as React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PrivacyStatement from '../components/PrivacyStatement';
import SEO from '../components/SEO';

export default function Privacy(props) {
	return (
		<SEO {...props} title="Privacy Policy | Buddy">
			<main>
				<Header />
				<div className="container mx-auto">
					<PrivacyStatement />
				</div>
				<div className="mt-24">
					<Footer />
				</div>
			</main>
		</SEO>
	);
}
