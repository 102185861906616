import React from 'react';
import { Link } from 'gatsby';

const Privacy = () => (
	<div className="max-w-2xl mx-auto p-4 md:p-0">
		<div className="my-8">
			<h1 className="text-center text-4xl">Privacy Policy</h1>
			<p className="text-center">(Version Date: November 17, 2017)</p>
		</div>
		<div>
			<h2 className="my-4 text-xl">Introduction</h2>
			<p className="mb-4">
				Buddy Technology, Inc., a Delaware corporation (
				<strong>"Company"</strong>
				{' '}
				or
				{' '}
				<strong>"We"</strong>
				) respect your privacy and are committed to
				protecting it through our compliance with this policy. This policy
				describes the types of information we may collect from you or that
				you may provide when you access or use Company’s services through
				the website my.buddy.insure (the
				{' '}
				<strong>"Website"</strong>
				), any third party using an application
				programming interface (
				<strong>"API"</strong>
				), the Company chatbot
				via any platform (the
				{' '}
				<strong>"Chatbot"</strong>
				), or any other medium or device now used
				or hereinafter developed (collectively, the
				{' '}
				<strong>"Services"</strong>
				) and our practices for collecting,
				using, maintaining, protecting, and disclosing that information.
			</p>
			<p className="mb-4">This policy applies to information we collect:</p>
			<ul className="list-disc pl-4 mb-4">
				<li>On the Website.</li>
				<li>
					In e-mail, text and other electronic messages between you and
					Company or any affiliate or agent of Company.
				</li>
				<li>
					Through mobile and desktop applications you download from the
					Website or on your cellular device, which provide dedicated
					non-browser-based interaction between you and Company.
				</li>
				<li>
					When you interact with our advertising and applications on
					third-party websites and services, including through API, if those
					applications or advertising include links to this policy.
				</li>
				<li>
					When you interact with the Chatbot through any platform or by any
					means.
					{' '}
				</li>
			</ul>
			<p className="mb-4">It does not apply to information collected by:</p>
			<ul className="list-disc pl-4 mb-4">
				<li>
					us offline or through any other means, including on any other
					website operated by Company or any third party; or
				</li>
				<li>
					any third party, including through any application or content
					(including advertising) that may link to or be accessible from or
					on the Website.
				</li>
			</ul>
			<p className="mb-4">
				Please read this policy carefully to understand our policies and
				practices regarding your information and how we will treat it. If
				you do not agree with our policies and practices, your choice is not
				to use our Services. By accessing or using our Services, you agree
				to this privacy policy. This policy may change from time to time
				(see
				<em>Changes</em>
				{' '}
				to our
				<em>Privacy Policy</em>
				). Your continued use of our Services after
				we make changes is deemed to be acceptance of those changes, so
				please check the policy periodically for updates.
			</p>
			<h2 className="my-4 text-xl">Children Under the Age of 18</h2>
			<p className="mb-4">
				Our Services are not intended for children under 18 years of age. No
				one under age 18 may provide any information to or on the Website.
				We do not knowingly collect personal information from children under
				18. If you are under 18, do not use or provide any information on
				this Website or on or through any of its features/register on the
				Website or otherwise, make any purchases through the Website, use
				any of the interactive or public comment features of this Website or
				otherwise, or provide any information about yourself to us,
				including your name, address, telephone number, e-mail address or
				any screen name or user name you may use. If we learn we have
				collected or received personal information from a child under 18
				without verification of parental consent, we will delete that
				information. If you believe we might have any information from or
				about a child under 18, please contact us at
				{' '}
				<a className="underline" href="mailto:support@buddy.insure">support@buddy.insure</a>
				.
			</p>
			<h2 className="my-4 text-xl">Information We Collect About You and How We Collect It</h2>
			<p className="mb-4">
				We collect several types of information from and about users of our
				Services, including information:
			</p>
			<ul className="list-disc pl-4 mb-4">
				<li>
					by which you may be personally identified, such as name, postal
					address, e-mail address, telephone number, social media ID, and
					social security number (
					<strong>"personal information"</strong>
					);
				</li>
				<li>
					that is about you but individually does not identify you; and/or
				</li>
				<li>
					about your internet connection, the equipment you use to access
					our Website and usage details.
				</li>
			</ul>
			<p className="mb-4">We collect this information:</p>
			<ul className="list-disc pl-4 mb-4">
				<li>Directly from you when you provide it to us.</li>
				<li>
					Automatically as you navigate through the site. Information
					collected automatically may include usage details, IP addresses
					and information collected through cookies, web beacons, and other
					tracking technologies.
				</li>
				<li>From third parties, for example, our business partners.</li>
				<li>
					Logs of all conversation between our Chatbot, customer service and
					you
				</li>
			</ul>
			<h2 className="my-4 text-xl">Information You Provide to Us</h2>
			<p className="mb-4">
				The information we collect on or through our Website or through your
				use of the Services may include:
			</p>
			<ul className="list-disc pl-4 mb-4">
				<li>
					Information that you provide by filling in forms on our Website,
					the Chatbot, or through a third party. This includes information
					provided at the time of registering to use our Website,
					subscribing to our Services, subscribing to our publications,
					posting material, making a purchase, or requesting further
					services.
				</li>
				<li>
					Records and copies of your correspondence (including e-mail
					addresses), if you contact us.
				</li>
				<li>
					Details of transactions you carry out through our Website, through
					the Chatbot, or through a third party, and of the fulfillment of
					your orders. You may be required to provide financial information
					before placing an order for the Services.
				</li>
				<li>Your search queries on the Website.</li>
				<li>Activity interests</li>
				<li>Location</li>
				<li>Travel destinations</li>
				<li>
					Conversation history from interactions with the Chatbot on our
					Website or elsewhere
				</li>
			</ul>
			<p className="mb-4">
				You also may provide information to be published or displayed
				(hereinafter,
				<strong>"posted"</strong>
				) on public areas of the Website, or
				transmitted to other users of the Website or third parties
				(collectively,
				<strong>"User Contributions"</strong>
				). Your User Contributions are
				posted on and transmitted to others at your own risk. Although we
				limit access to certain pages, please be aware that no security
				measures are perfect or impenetrable. Additionally, we cannot
				control the actions of other users of the Website with whom you may
				choose to share your User Contributions. Therefore, we cannot and do
				not guarantee that your User Contributions will not be viewed by
				unauthorized persons.
			</p>
			<h2 className="my-4 text-xl">
				Information We Collect through Automatic Data Collection
				Technologies
			</h2>
			<p className="mb-4">
				As you navigate through and interact with our Website or otherwise
				use the Services, we may use automatic data collection technologies
				to collect certain information about your equipment, browsing
				actions and patterns, including:
			</p>
			<ul className="list-disc pl-4 mb-4">
				<li>
					Details of your visits to our Website, including traffic data,
					location data, logs and other communication data and the resources
					that you access and use on the Website.
				</li>
				<li>
					Information about your computer and internet connection, including
					your IP address, operating system and browser type.
				</li>
			</ul>
			<p className="mb-4">
				We also may use these technologies to collect information about your
				online activities over time and across third- party websites or
				other online services (behavioral tracking).
			</p>
			<p className="mb-4">
				The information we collect automatically is statistical data and may
				include personal information, or we may maintain it or associate it
				with personal information we collect in other ways or receive from
				third parties. It helps us to improve our Services and to deliver a
				better and more personalized service, including by enabling us to:
			</p>
			<ul className="list-disc pl-4 mb-4">
				<li>Estimate our audience size and usage patterns.</li>
				<li>
					Store information about your preferences, allowing us to customize
					our Website according to your individual interests.
				</li>
				<li>Speed up your interactions.</li>
				<li>Recognize you when you return to our Website.</li>
			</ul>
			<p className="mb-4">
				The technologies we use for this automatic data collection may
				include:
			</p>
			<ul className="list-disc pl-4 mb-4">
				<li>
					<strong>Cookies (or browser cookies).</strong>
					{' '}
					A cookie is a small
					file placed on the hard drive of your computer. You may refuse to
					accept browser cookies by activating the appropriate setting on
					your browser. However, if you select this setting you may be
					unable to access certain parts of our Website. Unless you have
					adjusted your browser setting so that it will refuse cookies, our
					system will issue cookies when you direct your browser to our
					Website.
				</li>
				<li>
					<strong>Web Beacons.</strong>
					{' '}
					Pages on the Website and our e-mails
					may contain small electronic files known as web beacons (also
					referred to as clear gifs. pixel tags, and single-pixel gifs) that
					permit the Company, for example, to count users who have visited
					those pages or opened an e-mail and for other related website
					statistics (for example, recording the popularity of certain
					website content and verifying system and server integrity).
				</li>
			</ul>
			<p className="mb-4">
				We do collect some personal Information automatically, and we may
				tie other information to personal information about you that we
				collect from other sources or you provide to us.
			</p>
			<h2 className="my-4 text-xl">Third-party Use of Cookies and Other Tracking Technologies</h2>
			<p className="mb-4">
				Some content or applications, including advertisements, on the
				Website are served by third-parties, including advertisers, ad
				networks and servers, content providers and application providers.
				These third parties may use cookies alone or in conjunction with web
				beacons or other tracking technologies to collect information about
				you when you use our website. The information they collect may be
				associated with your personal information or they may collect
				information, including personal information, about your online
				activities over time and across different websites and other online
				services. They may use this information to provide you with
				interest-based (behavioral) advertising or other targeted content.
			</p>
			<p className="mb-4">
				We do not control these third parties' tracking technologies or how
				they may be used. If you have any questions about an advertisement
				or other targeted content, you should contact the responsible
				provider directly.
			</p>
			<h2 className="my-4 text-xl">How We Use Your Information</h2>
			<p className="mb-4">
				We use information that we collect about you or that you provide to
				us, including any personal information:
			</p>
			<ul className="list-disc pl-4 mb-4">
				<li>To present our Website and its contents to you.</li>
				<li>
					To provide you with information, products or Services that you
					request from us.
				</li>
				<li>To fulfill any other purpose for which you provide it.</li>
				<li>
					To provide you with notices about your account, including
					expiration and renewal notices.
				</li>
				<li>
					To carry out our obligations and enforce our rights arising from
					any contracts entered into between you and us, including for
					billing, payments, and collection.
				</li>
				<li>
					To notify you about changes to our Website or any products or
					Services we offer or provide though it.
				</li>
				<li>
					To allow you to participate in interactive features on our
					Website.
				</li>
				<li>For better conversational interfacing with our customers.</li>
				<li>
					In any other way we may describe when you provide the information.
				</li>
				<li>For any other purpose with your consent.</li>
			</ul>
			<p className="mb-4">
				We may also use your information to contact you about goods and
				services that may be of interest to you. If you do not want us to
				use your information in this way, please adjust your user
				preferences in your account profile.
			</p>
			<h2 className="my-4 text-xl">Disclosure of Your Information</h2>
			<p className="mb-4">
				We may disclose aggregated information about our users, and
				information that does not identify any individual, without
				restriction.
			</p>
			<p className="mb-4">
				We may disclose personal information that we collect or you provide
				as described in this privacy policy:
			</p>
			<ul className="list-disc pl-4 mb-4">
				<li>To our subsidiaries and affiliates.</li>
				<li>
					To contractors, service providers and other third parties we use
					to support our business and who are bound by contractual
					obligations to keep personal information confidential and use it
					only for the purposes for which we disclose it to them.
				</li>
				<li>
					To a buyer or other successor in the event of a merger,
					divestiture, restructuring, reorganization, dissolution or other
					sale or transfer of some or all of Company’s assets, whether as a
					going concern or as part of bankruptcy, liquidation or similar
					proceeding, in which personal information held by Company about
					our Service users is among the assets transferred.
				</li>
				<li>
					To fulfill the purpose for which you provide it. For example, if
					you give us an e-mail address to use the "e- mail a friend"
					feature, we will transmit the contents of that e-mail and your
					e-mail address to the recipients.
				</li>
				<li>
					For any other purpose disclosed by us when you provide the
					information.
				</li>
				<li>With your consent.</li>
			</ul>
			<p className="mb-4">We may also disclose your personal information:</p>
			<ul className="list-disc pl-4 mb-4">
				<li>
					To comply with any court order, law or legal process, including to
					respond to any government or regulatory request.
				</li>
				<li>
					To enforce or apply our
					{' '}
					<Link to="/terms" className="underline">
						terms of use
					</Link>
					{' '}
					and other agreements, including for billing and collection
					purposes.
				</li>
				<li>
					If we believe disclosure is necessary or appropriate to protect
					the rights, property, or safety of Company, our customers or
					others. This includes exchanging information with other companies
					and organizations for the purposes of fraud protection and credit
					risk reduction.
				</li>
			</ul>
			<h2 className="my-4 text-xl">Choices About How We Use and Disclose Your Information</h2>
			<p className="mb-4">
				We strive to provide you with choices regarding the personal
				information you provide to us. We have created mechanisms to provide
				you with the following control over your information:
			</p>
			<ul className="list-disc pl-4 mb-4">
				<li>
					<strong>Technologies and Advertising.</strong>
					{' '}
					You can set your
					browser to refuse all or some browser cookies, or to alert you
					when cookies are being sent. If you disable or refuse cookies,
					please note that some parts of this site may then be inaccessible
					or not function properly.
				</li>
				<li>
					<strong>Offers from the Company.</strong>
					{' '}
					If you do not wish to
					have your e-mail address/contact information used by the Company
					to promote our own or third parties' products or services, you can
					opt- out by checking the relevant box located on the form on which
					we collect your data (the order form])/clicking unsubscribe from
					any subsequent emails or at any other time by logging into the
					Website and adjusting your user preferences in your account
					profile by checking or unchecking the relevant boxes, asking a our
					chat bot to refrain from updates, or by sending us an e-mail
					stating your request to
					{' '}
					<a className="underline" href="mailto:support@buddy.insure">support@buddy.insure</a>
					. If
					we have sent you a promotional e-mail, you may send us a return
					e-mail asking to be omitted from future e-mail distributions. This
					opt out does not apply to information provided to the Company as a
					result of a product purchase, warranty registration, product
					service experience or other transactions.
				</li>
			</ul>
			<p className="mb-4">
				We do not control third parties’ collection or use of your
				information to serve interest-based advertising. However, these
				third parties may provide you with ways to choose not to have your
				information collected or used in this way. You can opt out of
				receiving targeted ads from members of the Network Advertising
				Initiative (
				<strong>"NAI"</strong>
				) on the NAI’s website.
			</p>
			<h2 className="my-4 text-xl">Accessing and Correcting Your Information</h2>
			<p className="mb-4">
				You can review and change your personal information by logging into
				the Website and visiting your account profile page.
			</p>
			<p className="mb-4">
				You may also send us an e-mail at
				{' '}
				<a className="underline" href="mailto:support@buddy.insure">support@buddy.insure</a>
				{' '}
				to
				request access to, correct or delete any personal information that
				you have provided to us. We cannot delete your personal information
				except by also deleting your user account. We may not accommodate a
				request to change information if we believe the change would violate
				any law or legal requirement or cause the information to be
				incorrect.
			</p>
			<p className="mb-4">
				If you delete your User Contributions from the Website, copies of
				your User Contributions may remain viewable in cached and archived
				pages, or might have been copied or stored by other Website users.
				Proper access and use of information provided on the Website,
				including User Contributions, is governed by our
				{' '}
				<Link to="/terms" className="underline">
					terms of use
				</Link>
				.
			</p>
			<h2 className="my-4 text-xl">Your California Privacy Rights</h2>
			<p className="mb-4">
				California Civil Code Section § 1798.83 permits users of our Website
				that are California residents to request certain information
				regarding our disclosure of personal information to third parties
				for their direct marketing purposes. To make such a request, please
				send an e-mail to
				{' '}
				<a className="underline" href="mailto:support@buddy.insure">support@buddy.insure</a>
				{' '}
				or
				write us at: Buddy Technology, Inc., Attn: California Privacy, P.O.
				Box 81, Richmond, VA 23218-0081.
			</p>
			<h2 className="my-4 text-xl">Data Security</h2>
			<p className="mb-4">
				We have implemented measures designed to secure your personal
				information from accidental loss and from unauthorized access, use,
				alteration and disclosure.
			</p>
			<p className="mb-4">
				The safety and security of your information also depends on you.
				Where we have given you (or where you have chosen) a password for
				access to certain parts of our Website, you are responsible for
				keeping this password confidential. We ask you not to share your
				password or credentials with anyone.
			</p>
			<p className="mb-4">
				Unfortunately, the transmission of information via the internet is
				not completely secure. Although we do our best to protect your
				personal information, we cannot guarantee the security of your
				personal information transmitted to our Website. Any transmission of
				personal information is at your own risk. We are not responsible for
				circumvention of any privacy settings or security measures contained
				on the Website or underlying systems.
			</p>
			<h2 className="my-4 text-xl">Changes to Our Privacy Policy</h2>
			<p className="mb-4">
				We may update Our privacy policy from time to time. If We make
				material changes to how We treat Our users’ personal information, We
				will post the new privacy policy. The date the privacy policy was
				last revised is identified at the top of the page. You are
				responsible for periodically visiting this privacy policy to check
				for any changes.
			</p>
			<h2 className="my-4 text-xl">Contact Information</h2>
			<p className="mb-4">
				To ask questions or comment about this privacy policy and our
				privacy practices, contact us at:
				{' '}
				<a className="underline" href="mailto:support@buddy.insure">support@buddy.insure</a>
				.
			</p>
		</div>
	</div>
);

export default Privacy;
